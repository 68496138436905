/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01, mq, jpFont01 } from "../constants/styleConstants";

export const voiceStyle = {
  voiceBlock: css`
    max-width: 80rem;
    margin: 8rem auto 4rem;
    padding: 0 1.25rem;
    ${mq('medium')} {
      margin-top: 9rem;
      margin-bottom: 8rem;
    }
  `,
  pageTitle: css`
    display: none;
  `,
  lede: css`
    text-align: center;
    margin-bottom: 4rem;
  `,
  voiceItemBlock: css`
    margin: 0 auto 5rem;
    max-width: 46.25rem;
    line-height: 2;
    ${mq('medium')} {
      margin-bottom: 8rem;
    }
  `,
  voiceProfileBlock: css`
    font-size: 0.75rem;
    margin-top: 2rem;
    div {
      margin-top: 1.25rem;
      //line-height: 1.8;
    }
  `,
  voiceProfileNameJp: css`
    ${jpFont01.bold};
    font-size: 0.9375rem;
    display: inline-block;
    line-height: 1.875rem;
    padding-bottom: 0.125rem;
    vertical-align: middle;
  `,
  voiceProfileNameEn: css`
    ${enFont01.bold};
    font-size: 1.1875rem;
    display: inline-block;
    margin-right: 1rem;
    line-height: 2rem;
    vertical-align: middle;
  `,
  voiceItemDescription: css`
    ${jpFont01.medium};
    font-size: 0.875rem;
    margin-top: 2rem;
  `,
}
