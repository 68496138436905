import React, { useCallback, useContext, useEffect, useRef } from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import { gsap } from "gsap";

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { voiceStyle } from "../components/voiceStyle";
import PageTitle from "../components/PageTitle/pageTitle";

import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import { duration, entryDelay, imageFadeDuration } from "../constants/componentConstants";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const VoicePage = () => {
  const data = useStaticQuery(graphql`
  query {
    voice01: file(relativePath: { eq: "voice/minamimagoe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    voice02: file(relativePath: { eq: "voice/ueno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);
  useEffect(() => {
    global.setHideHeaderLogo(false);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="VOICE" />

      <div css={voiceStyle.voiceBlock}>
        <PageTitle>VOICE</PageTitle>
        <div css={voiceStyle.lede}>
          今回、デザイナーを選出した南馬越一義と、<br />
          ムービーを撮り下ろした映像監督の上野千蔵がプロジェクトを振り返る。
        </div>
        <div css={voiceStyle.voiceItemBlock}>
          <div>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.voice01.childImageSharp.fluid}
            />
          </div>
          <div css={voiceStyle.voiceProfileBlock}>
            <h2>
              <span css={voiceStyle.voiceProfileNameEn}>KAZUYOSHI MINAMIMAGOE</span>
              <span css={voiceStyle.voiceProfileNameJp}>南馬越 一義</span>
            </h2>
            <div>
              1984年ビームスに入社。後に店長、バイヤー、レディース部門を統括するクリエイティブディレクターを経て、現在はビームス創造研究所のシニアクリエイティブディレクターを務める。世界中を飛び回り、旬なデザイナーを発掘し続け、フランス文化省主催の若手デザイナー支援コンクール「アンダム」の審査員としても活躍。
            </div>
          </div>
          <div css={voiceStyle.voiceItemDescription}>
            Q1：第一回に引き続き、今回もコラボレーションする3名のデザイナーを選出していただきました。どのようにプロジェクトを進行していったのかをお聞かせください。
            <br />
            <br />
            ──前回同様に、新進気鋭のデザイナーとコラボするという点は変わりませんが、まず三原さんにお声がけしました。すでに国内外で評価と名声を得ている方ですが、きっかけは昨年の展覧会を熱心に興味深く見て頂いたことです。昨年のプロジェクトに参加してくれたdoubletの井野さんが彼のアシスタントを務めていたという経緯があり、さらに学生時代に織物を勉強していたということを知り、ぜひお願いしたいと思ったんです。
            <br />
            <br />
            他の2名のデザイナーに関しては、いずれはこのプロジェクトを海外でも展示したいという想いがあり、そのことも考えて、パリコレで注目の若手デザイナーに参加してもらいたい旨を三原さんに相談したところ、ロク・ファンを紹介して頂きました。ロクはフィービー・ファイロが手がけていたCÉLINE時代にアシスタントをしていて、デビュー当時から才能にあふれていたので、即オファーをかけました。そして三人目は海外デザイナーでいくのならば、絶対にオファーしようと思っていたKOCHÉのクリステル・コシェです。僕は、彼女がVetementsの創設者デムナ・ヴァザリアと並ぶ才能の持ち主だと思っていて、繊細でドラマティックな刺繍とストリートライクな古着のリメイクがミックスされるコレクションを毎回ワクワクしながら見ていました。だから川島織物とコラボしたら必ず面白いものになると確信していましたね。三名とも川島織物のクリエーションにとても興味を抱き、快く参加してくれてとても嬉しいです。
            <br />
            <br />
            Q2：長きにわたってファッション界に携わっている南馬越さんが感じる、川島織物の魅力を聞かせてください。
            <br />
            <br />
            ──伝統的で、高度な技術や長年積み重ねてきた素晴らしいリソースを使って、新しいクリエーションにチャレンジする勇気と柔軟性にいつも感心しています。この「織物屋の試み」が次世代を魅了し、新しいリュクスを次々とクリエイトしていくことを願っています。
          </div>
        </div>

        <div css={voiceStyle.voiceItemBlock}>
          <div>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.voice02.childImageSharp.fluid}
            />
          </div>
          <div css={voiceStyle.voiceProfileBlock}>
            <h2>
              <span css={voiceStyle.voiceProfileNameEn}>SENZO UENO</span>
              <span css={voiceStyle.voiceProfileNameJp}>上野 千蔵</span>
            </h2>
            <div>
              1982年鹿児島県出身。撮影監督・映像作家として、数多くの広告、ミュージックビデオを手がける。Cannes Lions グランプリ、文化庁メディア芸術祭グランプリ、昨年のACCの撮影賞など、国内外の広告賞を多数受賞。2018年、ドキュメンタリー映画『太陽の塔 TOWER OF THE SUN』に撮影監督として参加。 2020年、映画『宇宙でいちばんあかるい屋根』の撮影も手がけている。
            </div>
          </div>
          <div css={voiceStyle.voiceItemDescription}>
            Q1：今回のムービーは、各デザイナーが手がけたチェアからどのようなインスピレーションを得て製作したのですか？
            <br />
            <br />
            ──三原さんの作品は伝統（紡がれてきた時間）。コシェさんの作品は多様性。ロクさんの作品は有機性というイメージを強く感じました。と同時に、それぞれが異なる国に住んでいるにも関わらず、彼らが一つのイメージを共有しているような印象も受けました。最終的にそれらは、川島織物セルコンの職人たちの手を通ることで一つに繋がっていく。3つのコンセプトをスムーズに一本の糸に繋げることができたと思っています。彼らはデザインに込めたメッセージを言葉を使わずに伝えることができる。アーティストの凄さを改めて感じることができました。だから、僕も最初に書いた映像のコンセプトをブレることなく製作できたと思います。
            <br />
            <br />
            Q2：川島織物の伝統技術を映像で伝える上で、一番こだわった点を聞かせてください。
            <br />
            <br />
            ──役者には演じることの出来ない、本物の職人さんだけがもつ技術、眼差し、そして美しい糸を僕の意図で邪魔しないように、なるべく普段通り作業しているところを丁寧に切り取りました。
          </div>
        </div>

        <div css={voiceStyle.voiceItemBlock}>
          <div>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.voice01.childImageSharp.fluid}
            />
          </div>
          <div css={voiceStyle.voiceProfileBlock}>
            <h2>
              <span css={voiceStyle.voiceProfileNameEn}>OSAMU SATO</span>
              <span css={voiceStyle.voiceProfileNameJp}>佐藤 修</span>
            </h2>
            <div>
              プロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィールプロフィール
            </div>
          </div>
          <div css={voiceStyle.voiceItemDescription}>
            Q1：今回のコラボを振り返り、3名のデザイナーからどのような刺激を受けましたか？ それぞれ一番印象的なエピソードをお聞かせください。
            <br />
            <br />
            ──“伝統とは革新すること”とよく言われますが、それを心より感じる企画でした。伝統技術を守るだけではなく、社会のニーズに合わせて変化させていく、既成概念を覆す。各デザイナーとの印象深いエピソードがたくさんあります。<br />
            <br />
            三原康裕さん<br />
            「あとから加工するのではなく、風化を織り成す」とおっしゃった時は、本当に驚きました。例えば、普段私たちは「工芸品としての古いもの」が価値あるものとして扱われるため、それをモチーフとして再現することはありますが、時間が経過した織物自体にエイジングされた美しさがあるのだ、ということを実感しました。<br />
            <br />
            ロク・ファンさん<br />
            織物は平面的なものと思っていましたが、触感やふわふわとしたテクスチャーまでも織り上げることができるのだ、ということに驚きと感動を覚えました。まさに、彼が思い描いた「森を織り上げる」ことができました。<br />
            <br />
            クリステル・コシェさん<br />
            これまで引箔の素材には、シルクや金銀のような天然の高級素材しか考えられないと思っていたのですが、その固定概念を覆したのが彼女斬新なアイデアでした。サッカージャージというある意味汎用的な布地が、引箔の魅力的な素材に成りうる。ラグジュアリーの概念やものづくりの楽しさなど、新しい発見を見出すことができました。
            <br />
            <br />
            Q2：ファッションとのコラボを通じて、川島織物セルコンが伝えていきたい技術や未来のヴィジョンを教えてください。
            <br />
            <br />
            ──「織物屋の試み」展では、デザイナーの皆さんの思いを具現化することにチャレンジしていますが、今回は我々が持つ技術を使用するだけでなく、アップデートすることができたと考えています。例えば、ユーズド・化学繊維・ジャージー素材などを使用することは今まで当社ではあり得なかったことです。また、当社が持つ絣×箔×織の技術とその他の伝統技術を組み合わせた形で試行錯誤し、伝統技術の掛け合わせで新しいものをつくりました。“伝統とは革新すること”──この言葉を今一度しっかりと心に留め、次の100年に向けて伝統技術の革新を試みながら、未来の織物を作っていきたいと思います。
          </div>
        </div>

      </div>

      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default VoicePage
